import React from 'react'
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="icon" /> 
                        Our Solutions
                    </span>
                    <h2>We Do Things Differently</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Hire Quickly
                                </Link>
                            </h3>
                            <p>Hire in less than 72 hours. Scale up or down as needed, when needed. Fully flexible engagements from hourly to full-time.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Reduce Costs
                                </Link>
                            </h3>
                            <p>No recruitment fees, no hidden costs. Reduce up to 30% while working with some of the brightest minds in the world.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Exceptional Talent Only
                                </Link>
                            </h3>
                            <p>All of our talent has gone through our rigorous screening process that includes not only technical skills, but aptitude and personality too.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions